.language_switch {
  top: 25px;
  right: 0;
  position: fixed;
}
.language_switch__wrapper {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 56px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 28px;
  margin: 0 1em 0 0;
}
.language_switch__wrapper input {
  display: none;
}
.language_switch__wrapper input:checked + .language_switch__slider:before {
  transform: translateX(60px);
}
.language_switch__wrapper input:checked ~ div:after {
  color: #ffffff;
  transition: color 0.3s ease 0s;
}
.language_switch__wrapper input:checked ~ div:before {
  color: #919191;
  transition: color 0.3s ease 0s;
}
.language_switch div::before {
  content: "🇺🇸";
  position: relative;
  z-index: 5;
  font-weight: 700;
  font-size: 42px;
  color: #ffffff;
  bottom: 3px;
  left: -10px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.language_switch div::after {
  content: "🇷🇺";
  position: relative;
  z-index: 5;
  font-weight: 700;
  font-size: 42px;
  color: #919191;
  bottom: 3px;
  left: 10px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.language_switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 34px;
}
.language_switch__slider::before {
  position: absolute;
  content: "";
  width: 70px;
  height: 56px;
  left: -1px;
  bottom: -1px;
  background-color: #969696;
  transition: all 0.4s ease 0s;
  border-radius: 28px;
}
