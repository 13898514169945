html {
  font-family: "Roboto", sans-serif;
  background-color: black;
}

body {
  margin: 0;
  padding: 0;
}

@media (min-width: 320px) {
  html {
    font-size: 1rem;
  }
}

@media (min-width: 414px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

#video {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

main {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
}

.main a {
  bottom: 20px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  transform: translate(0, -50%);
  color: #fff;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}

.main a:hover {
  opacity: 0.5;
}
