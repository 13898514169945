.iconsLink a {
  color: #fafafa;
}

.iconsLink a svg path {
  fill: #fafafa;
}

.iconsLink i {
  font-size: 3em;
  padding: 10px;
}
